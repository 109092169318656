import React from 'react';
import PropTypes from 'prop-types';

Advise.propTypes = {
    
};

function Advise(props) {
    return (
        <div>
            Advise
        </div>
    );
}

export default Advise;