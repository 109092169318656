import {
  faBriefcase,
  faBullhorn,
  faGear,
  faMagnifyingGlass,
  faPalette,
  faScroll,
} from "@fortawesome/free-solid-svg-icons";

export const jugeResult = {
  realistic: {
    color: "#566fe6",
    type_people: "hành động",
    group: "kỹ thuật",
    core_value:
      "Thực tế, Năng suất, Độc lập, Cấu trúc rõ ràng, Hoạt động thể chất",
    genitive:
      "Thực tế, Quyết đoán, Nghiêm túc, Nhạy cảm, Bảo thủ, Truyền thống, Khiêm tốn, Trầm tính",
    icon: faGear,
    feature: [
      "Đa số Người hành động thường thích làm công việc với có tương tác với máy móc, đồ vật hơn là con người. Nếu bạn là mẫu Người hành động, bạn có thể sẽ thích các hoạt động ngoài trời, chơi thể thao, và công việc có liên quan đến cây cối hoặc động vật. Bạn cũng sẽ thích việc xây dựng hoặc chế tạo đồ thủ công và làm việc với các dụng cụ.",
      "Thích làm hơn thích nói. Người hành động là mẫu người thực tế, có khuynh hướng hành động để đạt được kết quả cụ thể. Bạn có xu hướng tập trung vào 'các hoạt động đòi hỏi sự phối hợp vận động, kỹ năng và sức mạnh', và 'thích giải quyết vấn đề bằng cách làm điều gì đó, hơn là nói về nó hoặc ngồi và suy nghĩ về nó.'",
      "Thích trải nghiệm và những dự án thực tế hơn là học lý thuyết. Bạn có thể sẽ thích cách giải quyết vấn đề cụ thể, có qui trình rõ ràng, và mang tính ứng dụng cao hơn những lý thuyết trừu tượng. Bạn thích hoàn thành công việc với mức tối thiểu phiền phức.",
    ],
    advise_career_hobby:
      "Bạn có thể thích các công việc có liên quan đến Cơ khí, Máy móc, Sản xuất, Luật, Quân đội và Thể thao.",
    advise_career_consider:
      "Những công việc như tư vấn hoặc giảng dạy có thể khiến cho bạn cảm thấy quá áp lực và khó chịu vì những công việc này đòi hỏi bạn phải tiếp xúc nhiều người và bộc lộ cảm xúc nhiều.",
    advise_subject:
      "Anh văn, Toán, Khoa học, Kỹ thuật, Công nghệ Thông tin, Kinh doanh học, Tin học, Nông nghiệp, Lâm nghiệp, Giáo dục thể chất",
    advise_course:
      "Nhận biết và kiểm soát cảm xúc, Khả năng thấu cảm, Kỹ năng lắng nghe tích cực, Kỹ năng giao tiếp, Kỹ năng đàm phán và thuyết phục, Kỹ năng phản hồi hiệu quả, Kỹ năng tạo dựng mối quan hệ, Tư duy sáng tạo, Tư duy linh hoạt, Kỹ năng làm việc nhóm, Kỹ năng thuyết trình, Kỹ năng quản lý, Kỹ năng giải quyết vấn đề, Kỹ năng điều phối",
    banner: "https://rightpath.edu.vn/images/Data%20extraction-rafiki.png",
    job: [
      "Quản lý thể dục thể thao",
      "Lập trình viên phần mềm",
      "Chuyên viên kiểm thử phần mềm",
      "Chuyên viên quản trị mạng",
      "Chuyên viên quản trị dữ liệu DBA",
      "Kỹ sư AI nghiên cứu trí tuệ nhân tạo",
      "Chuyên viên phân tích dữ liệu",
      "Quản lý công nghệ thông tin",
      "Chuyên viên thiết kế Web",
      "Kỹ thuật viên Thiết kế đồ họa",
      "Kỹ sư truyền thông số",
      "Chuyên viên kỹ xảo điện ảnh Visual Effects-VF",
      "Kỹ sư máy tính",
      "Kỹ sư điện - điện tử",
      "Kỹ sư cơ khí/ô tô",
      "Kỹ sư Vận hành nhà máy điện",
      "Kỹ sư hàng không",
      "Phi công",
      "Kỹ sư xây dựng",
      "Kiến trúc sư",
      "Bác sỹ",
      "Kỹ thuật viên siêu âm",
      "Huấn luyện viên thể thao",
      "Quản lý y tế cộng đồng",
      "Y tá điều dưỡng",
      "Nhà hóa học",
      "Kiểm soát viên",
      "Công an",
      "Lính cứu hỏa",
      "Nghệ nhân Thủ công mỹ nghệ",
      "Chuyên gia ẩm thực",
      "Chuyên gia chăn nuôi/trồng trọt",
      "Nhiếp ảnh gia",
      "Nhà tạo mẫu tóc",
    ],
    major: [
      "Quản lý Thể dục thể thao",
      "Công nghệ thông tin",
      "Truyền thông đa phương tiện",
      "Điện - điện tử",
      "Chế tạo Robot và cơ điện tử",
      "Sửa chữa cơ khí",
      "Cơ khí ô tô",
      "Kỹ thuật hàng không",
      "Xây dựng",
      "Kiến trúc công trình",
      "Du lịch và khách sạn",
      "Quản lý chuỗi cung ứng và hậu cần",
      "Thủy lợi",
      "Lâm nghiệp",
      "Nông nghiệp học",
      "Giao thông vận tải",
      "Khoa học môi trường",
      "Khoa học sức khỏe",
      "Y",
      "Thú y",
      "Luật",
      "Nghiệp vụ an ninh",
      "Quân đội",
      "Nông nghiệp",
      "Chế biến thực phẩm",
      "Hoá học",
      "Toán học",
    ],
  },

  investigate: {
    color: "#34c38f",
    type_people: "tư duy",
    group: "nghiên cứu",
    core_value: "Khám phá, Hiểu biết, Logic, Độc lập, Trí thức",
    genitive:
      "Ham học hỏi, Tò mò, Logic, Uyên bác, Tỉ mỉ, chi tiết, Cẩn thận, Độc lập",
    icon: faMagnifyingGlass,
    feature: [
      "Người Tư duy là mẫu người thích 'suy nghĩ và quan sát hơn là hành động', và thích 'tổng hợp dữ liệu, phân tích thông tin' hơn là thuyết phục.",
      "Người Tư duy thường là những người trí thức, ham học hỏi, thích tìm kiếm dữ kiện và nghiên cứu. Bạn cũng bị thu hút để làm việc với 'dữ liệu' hơn làm việc với 'con người'.",
      "Nếu bạn là một Người tư duy, bạn có thể sẽ thích làm việc với các ý tưởng và lý thuyết. Bạn sẽ thích tìm tòi thông tin, tích lũy các kiến thức để có thể hiểu biết nhiều hơn về các vấn đề. Bạn có thể sẽ quan tâm đến khoa học và công nghệ.",
      "Bạn thích làm việc độc lập, hoặc làm việc trong nhóm nhỏ hơn là làm việc với nhiều người. Bạn là con người của học thuật, duy lý và thích các hệ thống tư duy logic.",
    ],
    advise_career_hobby:
      "Bạn có thể thích các nghề nghiệp trong lĩnh vực khoa học, công nghệ, kỹ thuật, toán học, nghiên cứu, học thuật và y học.",
    advise_career_consider:
      "Người tư duy không hợp các công việc phức tạp, không có dữ liệu hướng dẫn và hay phải giải quyết vấn đề, bạn nên cân nhắc các công việc như Đại lý kinh doanh, Đại lý bảo hiểm...",
    advise_subject: "Anh văn, Toán, Khoa học, Kỹ thuật, Công nghệ thông tin",
    advise_course:
      "Nhận biết và kiểm soát cảm xúc, Khả năng thấu cảm, Kỹ năng làm việc nhóm, Kỹ năng hợp tác, Kỹ năng giao tiếp, Kỹ năng ra quyết định, Kỹ năng đàm phán và thuyết phục, Kỹ năng quản lý, Kỹ năng giải quyết vấn đề, Kỹ năng quản trị rủi ro, Kỹ năng lập kế hoạch và tổ chức công việc, Kỹ năng phân công và ủy thác công việc, Kỹ năng tạo dựng mối quan hệ",
    banner: "https://rightpath.edu.vn/images/Woman%20reading-bro.png",
    job: [
      "Lập trình viên phần mềm",
      "Chuyên viên kiểm thử phần mềm",
      "Chuyên viên quản trị mạng",
      "Chuyên viên quản trị dữ liệu DBA",
      "Kỹ sư AI nghiên cứu trí tuệ nhân tạo",
      "Chuyên viên phát triển ứng dụng",
      "Chuyên viên phân tích dữ liệu",
      "Chuyên viên thiết kế Web",
      "Kỹ thuật viên Thiết kế đồ họa",
      "Kỹ sư truyền thông số",
      "Kỹ sư máy tính",
      "Nhà kinh tế học",
      "Chuyên gia phân tích tài chính",
      "Chuyên viên thẩm định bồi thường bảo hiểm",
      "Quản lý hậu cần",
      "Kế toán - Kiểm toán",
      "Bác sĩ",
      "Nha sĩ",
      "Dược sĩ",
      "Chuyên gia tham vấn tâm lý",
      "Công nghệ thực phẩm",
      "Chuyên gia dinh dưỡng",
      "Kỹ sư xây dựng",
      "Kiến trúc sư",
      "Kỹ sư hàng không",
      "Kỹ sư công nghiệp",
      "Kỹ sư dầu khí",
      "Kỹ sư hạt nhân",
      "Giám đốc Khoa học Tự nhiên",
      "Nhà Sinh vật học/Hóa học/Vật lý/Toán học/Thiên văn học",
      "Nhà động vật học",
      "Nghiên cứu viên",
      "Nhà khoa học nghiên cứu máy tính",
      "Giáo sư đại học",
      "Luật sư",
      "Thủ thư",
      "Thợ mộc"
  ],
    major: [
      "Công nghệ thông tin",
    "Truyền thông đa phương tiện",
    "Quản lý chuỗi cung ứng và hậu cần",
    "Điện - điện tử",
    "Kỹ thuật hàng không",
    "Kỹ thuật công nghiệp",
    "Giao thông vận tải",
    "Y khoa",
    "Tâm lý học",
    "Tâm thần học",
    "Tội phạm học",
    "Luật",
    "Nghiệp vụ an ninh",
    "Quân đội",
    "Thú y",
    "Nông nghiệp",
    "Chế biến thực phẩm"
    ],
  },

  artistic: {
    color: "#1cbed7",
    type_people: "sáng tạo",
    group: "nghệ thuật",
    core_value: "Độc đáo, Sáng tạo, Tự do, Cá tính, Linh hoạt",
    genitive:
      "Độc lập, Trực giác nhạy bén, Nhạy cảm, Giàu trí tưởng tượng, Không thích gò bó",
    icon: faPalette,
    feature: [
      "Người sáng tạo chủ yếu quan tâm đến những công việc liên quan đến “sự sáng tạo, độc đáo và thể hiện nghệ thuật”. Bạn thường là người giàu trí tưởng tượng, trực quan, và thích tạo ra thứ gì đó sáng tạo và độc đáo. Bạn có thể thích các hoạt động như vẽ, viết sáng tạo hoặc chơi một nhạc cụ. Dù không phải là nghệ sĩ nhưng bạn có thể sẽ thích được trải nghiệm nghệ thuật, âm nhạc hoặc kịch.",
      "Người sáng tạo nổi loạn chống lại 'cấu trúc và quy tắc', và không thích các nhiệm vụ liên quan đến con người hoặc kỹ năng thể chất. Do đó, bạn thích làm việc trong một môi trường không bị gò bó, nơi mà bạn có thể tạo ra thứ gì đó độc đáo và mang bản sắc của riêng mình.",
    ],
    advise_career_hobby:
      "Bạn có thể thích các nghề nghiệp trong các trong các lĩnh vực nghệ thuật, thiết kế, biểu diễn, âm nhạc, viết lách và ngôn ngữ.",
    advise_career_consider:
      "Bạn có thể cảm thấy chán khi làm những công việc đơn điệu, lặp đi lặp lại mỗi ngày như hành chính văn phòng. Bạn có thể bị stress khi phải làm những công việc yêu cầu tính toán, thiếu sự sáng tạo, ít thể hiện cảm xúc như các nghề trong lĩnh vực kế toán, kinh doanh, kỹ thuật, quản lý…",
    advise_subject:
      "Anh văn, Xã hội, Âm nhạc, Kịch, Nghệ thuật, Thiết kế đồ họa, Tin học, Kinh doanh học, Văn học",
    advise_course:
      "Tư duy phản biện, Nhận biết và kiểm soát cảm xúc, Kỹ năng đối mặt với áp lực, Kỹ năng tự phục hồi, Kỹ năng tạo động lực cho bản thân, Kỹ năng giao tiếp, Kỹ năng tiếp thu và phản hồi thông tin, Kỹ năng làm việc nhóm, Kỹ năng tạo dựng mối quan hệ, Kỹ năng quản lý thời gian, Kỹ năng quản lý dự án, Kỹ năng sắp xếp công việc hiệu quả, Kỹ năng giải quyết vấn đề",
    banner: "https://rightpath.edu.vn/images/Audiobook-pana.png",
    job: [
      "Chuyên viên Marketing",
    "Nhà phân tích nghiên cứu thị trường",
    "Đại lý công ty Quảng cáo",
    "Chuyên viên Thiết kế Web",
    "Chuyên viên Thiết kế đồ họa",
    "Chuyên viên Thiết kế game",
    "Chuyên viên Thiết kế phim hoạt hình",
    "Chuyên viên Thiết kế quảng cáo",
    "Chuyên viên Thiết kế sân khấu và sự kiện",
    "Chuyên viên Kỹ xảo điện ảnh",
    "Chuyên viên Sản xuất hậu kỳ",
    "Chuyên viên Truyền Thông",
    "Kỹ thuật viên Phát thanh Truyền hình",
    "Nhà sản xuất âm nhạc và video",
    "Biên tập hậu kỳ điện ảnh",
    "Biên tập viên",
    "Kỹ thuật viên Phát thanh Truyền hình",
    "Giám đốc âm nhạc",
    "Giám đốc sáng tạo",
    "Giám đốc nghệ thuật",
    "Chuyên viên Quan hệ công chúng",
    "Thiết kế thời trang",
    "Nhà tạo mẫu thời trang - Fashion stylist",
    "Thiết kế công nghiệp",
    "Thiết kế nội thất",
    "Chuyên gia Tham vấn tâm lý",
    "Nghệ nhân Thủ công mỹ nghệ",
    "Biên phiên dịch",
    "Kiến trúc sư",
    "Nhiếp ảnh gia",
    "Chuyên gia ẩm thực/ trang điểm",
    "Nhạc sĩ / Ca sĩ / Nhà văn",
    "Biên đạo múa/ Vũ công",
    "Giáo viên"
    ],
    major: [
      "Marketing",
    "Truyền thông đa phương tiện",
    "Thiết kế đồ họa",
    "Thiết kế thời trang",
    "Thiết kế nội thất",
    "Quản lý nhà hàng khách sạn",
    "Kiến trúc",
    "Tâm lý học",
    "Ngoại ngữ",
    "Đạo diễn điện ảnh, truyền hình",
    "Diễn viên kịch, điện ảnh",
    "Diễn viên sân khấu kịch hát",
    "Đạo diễn sân khấu",
    "Thiết kế mỹ thuật, sân khấu, điện ảnh",
    "Công nghệ điện ảnh truyền hình",
    "Biên kịch điện ảnh, truyền hình",
    "Nhiếp ảnh",
    "Quay phim",
    "Biên đạo múa",
    "Huấn luyện múa",
    "Trang điểm thẩm mỹ"
    ],
  },

  social: {
    color: "#f1b44c",
    type_people: "giúp đỡ",
    group: "xã hội",
    core_value: "Hợp tác, Phục vụ, Vị tha, Kết nối, Đồng cảm",
    genitive: "Có lòng trắc ẩn, Kiên nhẫn, Hay giúp đỡ, Thân thiện, Rộng lượng",
    icon: faBullhorn,
    feature: [
      "Người giúp đỡ là người có mối quan tâm xã hội và chủ yếu quan tâm đến những công việc giúp đỡ hoặc phục vụ người khác. Những người giúp đỡ thường là những người có lòng nhân ái, biết quan tâm.",
      "Nếu bạn là Người giúp đỡ, bạn có thể sẽ thích làm việc chặt chẽ với những người khác theo cách cho phép bạn tạo ra tác động tích cực đến cuộc sống của họ. Bạn có thể thích giảng dạy, tư vấn và chăm sóc người khác. Chọn công việc cho phép bạn sống đúng với giá trị cá nhân của mình là điều rất quan trọng đối với bạn. Bạn thường không thích làm việc một mình. Bạn rất coi trọng các mối quan hệ của mình và muốn làm việc trong sự hợp tác và hòa hợp với những người khác.",
    ],
    advise_career_hobby:
      "Bạn có thể thích các nghề nghiệp trong lĩnh vực chăm sóc sức khỏe, dịch vụ xã hội, tư vấn, nhân sự và chăm sóc cá nhân.",
    advise_career_consider:
      "Người giúp đỡ thích những công việc có tính kết nối xã hội và giúp đỡ được nhiều người. Những nghề nghiệp làm việc độc lập hoặc dựa trên dữ liệu như Tài xế xe tải, Thợ mộc, Lập trình viên máy tính... có thể làm cho bạn cảm thấy buồn chán.",
    advise_subject:
      "Tiếng Anh, Xã hội học, Toán, Khoa học, Giáo dục thể chất, Nghệ thuật, Máy tính, Kinh doanh, Ngôn ngữ",
    advise_course:
      "Tư duy phân tích, Kỹ năng đối mặt với áp lực, Kỹ năng làm việc độc lập, Kỹ năng quản lý thời gian, Kỹ năng công nghệ, Kỹ năng thu thập và xử lý thông tin, Kỹ năng quản lý dự án, Kỹ năng giải quyết vấn đề, Kỹ năng thiết lập mục tiêu và ra quyết định, Kỹ năng Tư duy tập trung vào kết quả",
    banner: "https://rightpath.edu.vn/images/Group-bro.png",
    job: [
      "Chuyên viên truyền thông Marketing",
    "Quản lý thể thao",
    "Chuyên viên Tổ chức sự kiện",
    "Huấn luyện viên thể thao",
    "Nhân viên Giải quyết khiếu nại",
    "Chuyên viên quan hệ đối ngoại",
    "Chuyên viên quan hệ khách hàng",
    "Chuyên gia chiến lược PR",
    "Nhà báo",
    "Quản lý giáo dục",
    "Giáo viên",
    "Chuyên gia tham vấn tâm lý",
    "Nhân viên Công tác xã hội",
    "Điều dưỡng",
    "Nha sĩ",
    "Bác sĩ",
    "Dược sĩ",
    "Nhân viên sức khỏe cộng đồng",
    "Nhân viên vật lý trị liệu",
    "Quản lý nhân sự",
    "Nhân viên hành chính",
    "Chuyên gia dinh dưỡng",
    "Nhân viên dịch vụ khách hàng",
    "Luật sư",
    "Thủ thư",
    "MC"
    ],
    major: [
      "Marketing",
    "Quản lý Thể dục thể thao",
    "Truyền thông chuyên nghiệp",
    "Báo chí",
    "Ngoại giao",
    "Tâm lý học",
    "Sư phạm",
    "Giáo dục đặc biệt",
    "Quản trị văn phòng",
    "Quản trị nhân sự",
    "Quản lý thông tin",
    "Lịch sử",
    "Nhân học",
    "Triết học",
    "Xã hội học",
    "Giáo dục học",
    "Quản trị dịch vụ du lịch và lữ hành",
    "Luật",
    "Y khoa",
    "Quan hệ công chúng"
    ],
  },

  enterprising: {
    color: "#f46a6a",
    type_people: "thuyết phục",
    group: "quản lý",
    core_value: "Ảnh hưởng, Lãnh đạo, Mạo hiểm, Thành tích, Chủ động",
    genitive: "Quả quyết, Mãnh liệt, Tự tin, Tham vọng, Táo bạo",
    icon: faScroll,
    feature: [
      "Người thuyết phục là một người năng nổ, có tố chất lãnh đạo, mạnh mẽ, và luôn biết cách tự tạo động lực trong công việc. Nếu bạn là một Người thuyết phục, bạn có thể sẽ thích làm việc ở những vị trí có quyền lực và tầm ảnh hưởng. Bạn có tài ăn nói và có thể vận dụng kỹ năng này để lãnh đạo hoặc thuyết phục người khác. Hầu hết những người thuyết phục đều thích công việc có 1 ít mạo hiểm nhất định. Do đó những công việc có 1 chút thử thách có thể khiến cho bạn cảm thấy hứng thú.",
      "Người thuyết phục là người thích làm việc với 'con người và dữ liệu'. Bạn không thích chỉ làm việc một mình với các dữ liệu, dự án mà bạn thích hợp tác với những người khác để hoàn thành các dự án và đạt được các mục tiêu của tổ chức.",
    ],
    advise_career_hobby:
      "Bạn có thể thích các công việc có liên quan đến kinh doanh, quản lý, chính trị, luật hoặc lãnh đạo. Bạn có đủ khả năng lập kế hoạch phát triển bản thân để có thể thăng tiến lên các vị trí quản lý.",
    advise_career_consider:
      "Vốn hòa đồng và thích làm việc với nhiều người khác nhau, bạn có khả năng gặp khó khăn trong những nghề nghiệp đòi hỏi ít giao tiếp xã hội hoặc cơ hội để ảnh hưởng đến người khác. Do đó, các nghề như nhà khoa học, lập trình viên máy tính, thủ thư hoặc kỹ sư mạng có thể làm nản lòng bạn.",
    advise_subject:
      "Anh văn, Toán, Kinh tế, Kế toán, Kinh doanh, Xã hội học, Tin học, Ngôn ngữ",
    advise_course:
      "Nhận biết và kiểm soát cảm xúc, Khả năng thấu cảm, Kỹ năng đối mặt với áp lực, Kỹ năng công nghệ, Kỹ năng quản lý dự án, Kỹ năng Tư duy tập trung vào kết quả, Kỹ năng phân công và ủy thác công việc, Kỹ năng Quản trị hiệu suất làm việc, Kỹ năng tiếp thu và phản hồi thông tin hiệu quả",
    banner: "https://rightpath.edu.vn/images/Coding%20workshop-pana.png",
    job: [
      "Giám đốc đào tạo kinh doanh",
    "Quản lý Công nghệ thông tin",
    "Nhà phát triển Web",
    "Chuyên viên lập trình máy tính",
    "Đại lý Bất động sản",
    "Trưởng phòng Quảng cáo",
    "Quản lý Marketing",
    "Quản lý thể thao",
    "Trưởng phòng hành chính",
    "Giám đốc tài chính",
    "Quản lý hệ thống thông tin",
    "Giám đốc quan hệ công chúng",
    "Giám đốc sản xuất công nghiệp",
    "Trưởng phòng nhân sự",
    "Tư vấn kế toán thuế",
    "Kế toán trưởng",
    "Quản lý y tế cộng đồng",
    "Chuyên viên bồi thường",
    "Kiểm soát viên không lưu",
    "Quản lý y tế",
    "Luật sư",
    "Giáo viên",
    "Diễn giả"
    ],
    major: [
      "Quản trị Kinh doanh",
    "Kinh doanh quốc tế",
    "Bất động sản",
    "Marketing",
    "Quản lý thể thao",
    "Kinh tế tài chính",
    "Hệ thống thông tin kinh doanh",
    "Tài chính - Ngân hàng",
    "Kinh doanh thương mại",
    "Kinh tế đối ngoại",
    "Quản trị dịch vụ du lịch và lữ hành",
    "Quản trị du lịch và khách sạn",
    "Quản trị nguồn nhân lực",
    "Thương mại",
    "Ngoại thương",
    "Kế toán",
    "Phân tích và kiểm toán",
    "Logistics và Quản lý chuỗi cung ứng",
    "Luật kinh doanh",
    "Quản trị bệnh viện"
    ],
  },

  conventional: {
    color: "#7f92ec",
    type_people: "tổ chức",
    group: "nghiệp vụ",
    core_value: "Có tổ chức, Trật tự, Rõ ràng, Chính xác, Chi tiết",
    genitive: "Nề nếp, Chính xác, Chi tiết, Thận trọng, Kỹ lưỡng",
    icon: faBriefcase,
    feature: [
      "Người tổ chức có tính tự chủ cao và sẽ làm việc một cách có tổ chức và bài bản để hoàn thành mọi việc đúng thời hạn. Bạn có thể thích làm việc với các con số, dữ liệu và các tệp hồ sơ.",
      "Bạn coi trọng độ chính xác và rõ ràng trong công việc, và thích tuân theo các quy tắc. Bạn có thể có sở thích và có khả năng làm việc trong môi trường yêu cầu làm việc với nhiều người, nhiều công việc, và đòi hỏi có tính nghiệp vụ như công việc hành chính, văn phòng.",
    ],
    advise_career_hobby:
      "Người tổ chức thường chọn các ngành nghề kinh doanh, quản trị, kế toán, công nghệ thông tin và quản lý văn phòng.",
    advise_career_consider:
      "Bạn có thể gặp khó khăn với các nghề nghiệp quá nhàn hạ, có nhiều thay đổi đột xuất hoặc phát sinh các yếu tố bất ngờ như nghệ sĩ, dancer, nhà sản xuất truyền thông…Tương tự, những công việc có nhiều tình huống không thể đoán trước như lính cứu hỏa hoặc nhân viên y tế có thể gây căng thẳng cho bạn.",
    advise_subject:
      "Anh văn, Toán, Kinh tế, Kế toán, Kinh doanh, Tin học, Ngôn ngữ, Quản trị hệ thống thông tin",
    advise_course:
      "Tư duy linh hoạt, Năng lực sáng tạo và đổi mới, Kỹ năng quản lý thời gian, Kỹ năng xử lý tình huống, Kỹ năng sắp xếp công việc hiệu quả, Kỹ năng giải quyết vấn đề, Kỹ năng quản lý sự thay đổi, Kỹ năng quản trị rủi ro",
    banner: "https://rightpath.edu.vn/images/Business%20analytics-bro.png",
    job: [
    "Quản lý Marketing",
    "Đại lý bất động sản",
    "Lập trình viên máy tính",
    "Quản lý Công nghệ thông tin",
    "Chuyên gia kiểm thử phần mềm",
    "Nhân viên hỗ trợ IT",
    "Chuyên viên phát triển ứng dụng",
    "Kỹ sư mạng",
    "Kiến trúc sư phần mềm",
    "Quản lý nhân sự",
    "Giám đốc Logistics",
    "Chuyên gia phân tích tài chính",
    "Giao dịch viên ngân hàng",
    "Kế toán – Kiểm toán",
    "Nhân viên thẩm định bảo hiểm",
    "Nhân viên Hành chính văn phòng",
    "Phục vụ khách hàng",
    "Thư ký",
    "Thủ thư",
    "Trợ lý nha khoa",
    "Quản lý xây dựng",
    "Quản lý trưng bày triển lãm",
    "Nhà Tâm lý học Tổ chức - Công nghiệp",
    "Tư vấn quản lý kinh doanh",
    "Giám đốc điều hành COO",
    "Quản lý thu mua",
    "Giáo viên",
    "Kỹ sư Xây dựng",
    "Kỹ sư Môi trường",
    "Kỹ sư dầu khí",
    "Tiếp viên Hàng không",
    "Phi công",
    "Kiểm soát không lưu",
    "Thợ máy/Thợ hàn/Thợ điện"
    ],
    major: [
      "Marketing",
    "Bất động sản",
    "Công nghệ thông tin",
    "Quản trị kinh doanh",
    "Kinh doanh quốc tế",
    "Quản lý chuỗi cung ứng và hậu cần",
    "Khoa học thư viện",
    "Kinh tế tài chính",
    "Kế toán - Kiểm toán",
    "Quản trị nhân sự",
    "Xây dựng",
    "Kiến trúc",
    "Kỹ thuật cơ khí",
    "Kỹ thuật môi trường",
    "Kỹ thuật dầu khí",
    "Kiểm soát không lưu",
    "Hàng không",
    "Sư phạm"
    ],
  },
};
