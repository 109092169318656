import React from 'react';
import PropTypes from 'prop-types';

AboutUs.propTypes = {
    
};

function AboutUs(props) {
    return (
        <div>
            AboutUs
        </div>
    );
}

export default AboutUs;